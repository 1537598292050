<template>
  <div class="home-page" v-show="!data.showQrcode">
    <div class="logo-content">
      <div style="height: 180px;"></div>
      <img src="@/assets/images/logo.png"  class="logo"/>
      <div class="title" >无边界 TECH X</div>
      <div class="title-info">一物一码标准施工管理系统</div>
      <div class="title-info" style="margin-top: 120px;">瑞士技术认证 | 瑞士施工工艺</div>
    </div>
    <div class="btn-content">
      <div class="scan-btn" @click="getCameras">扫描二维码</div>
    </div>
    <div class="footer-info">扫码验真/标准施工/品质验收</div>
  </div>
  <div class="qrcode-content" v-show="data.showQrcode">
    <div id="qrReader"></div>
  </div>
</template>

<script setup>
import { Html5Qrcode } from "html5-qrcode";
import { reactive } from "vue"
import { useRouter } from "vue-router"

let data = reactive({
  showQrcode: false,
  html5Qrcode: null
});
let router = useRouter();






let openQrcode = () => {
  data.showQrcode = true;
  data.html5Qrcode.start(
    { facingMode: "environment" },
    {
      fqs: 2,
      qrbox: {width: 250, height: 250}
    },
    (decodedText, decodedResult) => {
      if(decodedText && decodedText.indexOf("www.tmgxbxwl.cn/#/verify") !== -1){
        let textList = decodedText.split("?id=");
        let id = textList[1]
        router.push({
          path: "/verify",
          query: {
            id: id
          }
        });
      }
    }
  )
}

let getCameras = () => {
  Html5Qrcode.getCameras().then((devices) => {
    if(devices && devices.length > 0){
      data.html5Qrcode = new Html5Qrcode("qrReader");
      openQrcode();
    }
  })
}


</script>
<style scoped lang="less">
.qrcode-content{
  width: 100%;
  height: 100%;
 
}
.home-page{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.logo-content{
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo{
    width: 200px;
  }
  .title{
    font-size: 32px;
    color: #383838;
    margin-top: 90px;
  }
  .title-info{
    font-size: 16px;
    color: #383838;
  }
}
.btn-content{ 
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scan-btn{
  width: 80%;
  height: 48px;
  font-size: 16px;
  background-color: rgba(185, 28, 37, 1);
  text-align: center;
  color: #fff;
  line-height: 48px;
  border-radius: 9px;
  &:active{
    background-color: rgba(185, 28, 37, 0.5);
  }
}
.footer-info{
  color: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 10%;
  text-align: center;
}
</style>
