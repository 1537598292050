<template>
  <Header :step="data.currentStep" v-if="data.currentStep !== 3"></Header>
  <div class="step-page" v-if="data.currentStep !== 3">
    <div class="step-1" v-if="data.currentStep == 1">
      <div class="info">步骤一</div>
      <div class="title">首次验证 放心使用</div>
      <div class="verify-success">
        <img src="@/assets/images/verify-success.png"/>
        验证成功</div>
      <div class="form-title" style="margin-top: 30px">地址</div>
      <input class="form-input" v-model="data.step1Data.location"/>
      <div class="form-title" >服务商</div>
      <input class="form-input" v-model="data.step1Data.provider"/>
      <div class="form-title">安装师傅</div>
      <input class="form-input" v-model="data.step1Data.installerName"/>
      <div class="form-title">施工面积</div>
      <input class="form-input" v-model="data.step1Data.constructionArea"/>
      <Spin :spinning="data.putLoading">
        <div class="scan-btn next-btn" @click="nextStep()">
          下一步
        </div>
      </Spin>
      
    </div>
    <div class="step-2" v-else-if="data.currentStep == 2">
      <div class="info">步骤二</div>
      <div class="title">双向验证 真实可信</div>
      <div class="verify-info">确保每一次服务都能到岗到位到家</div>
      <div class="form-title" style="margin-top: 60px">业主手机号</div>
      <div class="phone-input">
        <input class="form-input" v-model="data.step2Data.customerPhoneNumber"/>
        <div class="btn" @click="postSmsData" :class="data.phoneBtnDisableTime > 0? 'disabled':''">
          <span v-if="data.phoneBtnDisableTime > 0">{{data.phoneBtnDisableTime}}秒</span>
          <span v-else>发送验证码</span>
        </div>
      </div>
      <div class="form-title">短信验证码</div>
      <input class="form-input" v-model="data.step2Data.code"/>
      <div class="form-title">服务评分</div>
      <div class="rate">
        <Rate v-model:value="data.step2Data.customerScore" style="display: flex;font-size: 30px;justify-content: space-between;width: 100%;">
        </Rate>
      </div>

        <Spin :spinning="data.putLoading">   
          <div class="scan-btn next-btn" @click="nextStep()">下一步</div>
          <div class="scan-btn back-btn" @click="backStep()">
              上一步
          </div>
        </Spin>
    </div>
    
    <div class="step-4" v-if="data.currentStep == 4">
      <div class="info">步骤四</div>
      <div class="title">提交激活</div>
      <div class="action-item" style="margin-top: 30px">
        <div class="title">激活时间</div>
        <div class="value">{{getNowFormatDate(new Date())}}</div>
      </div>
      <div class="action-item">
        <div class="title">位置</div>
        <div class="value">{{data.step1Data.location}}</div>
      </div>
      <div class="action-item">
        <div class="title">服务商</div>
        <div class="value">{{data.step1Data.provider}}</div>
      </div>
      <div class="action-row">
        <div class="action-item row-item">
          <div class="title">施工师傅</div>
          <div class="value">{{data.step1Data.installerName}}</div>
        </div>
        <div class="action-item row-item">
          <div class="title">施工面积</div>
          <div class="value">{{data.step1Data.constructionArea}}m2</div>
        </div>
      </div>
      <div class="action-row">
        <div class="action-item row-item">
          <div class="title">业主评分</div>
          <div class="value">{{data.step2Data.customerScore}}</div>
        </div>
        <div class="action-item row-item">
          <div class="title">业主电话</div>
          <div class="value">{{data.step2Data.customerPhoneNumber}}</div>
        </div>
      </div>
      <div class="form-title" style="margin-bottom: 80px;">业主签名</div>
      <img v-if="data.signaturePngUrl" :src="data.signaturePngUrl" class="sign-img">
      <img v-else-if="data.signaturePng" :src="data.signaturePng" class="sign-img"/>
      <Spin :spinning="data.putLoading">  
      <div class="scan-btn next-btn" style="margin-top: 20px;" @click="nextStep()">下一步</div>
      <div class="scan-btn back-btn" @click="backStep()">
              上一步
      </div>
      </Spin>
    </div>
    <div class="step-5" v-else-if="data.currentStep == 5">
      <div class="flex-content">
      <img src="@/assets/images/done.png"/>
      <div class="title">恭喜您，激活成功</div>
      <div class="info">已提交数据无法更改，如有任何问题可联系</div>
      <div class="info">无边界后台服务管理系统</div>
      </div>
      <Spin :spinning="data.putLoading" style="width: 100%;">
        <div class="scan-btn next-btn" style="margin-top: 130px;" @click="nextStep()">完成</div>
       </Spin>
    </div>
    <div class="step-6" v-else-if="data.currentStep == 6">
      <div class="success-title">
        <img src="@/assets/images/done.png"/>
        已激活
      </div>
         <div class="action-item" style="margin-top: 48px">
        <div class="title">激活时间</div>
        <div class="value">{{getNowFormatDate(data.activationTimestamp * 1000)}}</div>
      </div>
      <div class="action-item" style="margin-top: 32px">
        <div class="title">位置</div>
        <div class="value">{{data.step1Data.location}}</div>
      </div>
      <div class="action-item" style="margin-top: 32px">
        <div class="title">服务商</div>
        <div class="value">{{data.step1Data.provider}}</div>
      </div>
      <div class="action-item" style="margin-top: 32px">
        <div class="title">施工师傅</div>
        <div class="value">{{data.step1Data.installerName}}</div>
      </div>
      <div class="action-item" style="margin-top: 32px">
        <div class="title">施工面积</div>
        <div class="value">{{data.step1Data.constructionArea}}m2</div>
      </div>
     
    </div>

  </div>
  <div class="footer-info footer" v-if="data.currentStep !== 3">扫码验真/标准施工/品质验收</div>
  <div class="step-3" v-show="data.currentStep == 3" ref="stepThree">

    <canvas ref="signatureCanvas"></canvas>
    <ColumnHeader @nextStep="nextStep" @clear="clearSignature"></ColumnHeader>
  </div>
</template>
<script setup>
import Header from "@/component/Header.vue"
import ColumnHeader from "@/component/ColumnHeader.vue"
import { Rate,message,InputSearch, Spin } from 'ant-design-vue';
import SmoothSignature from "smooth-signature";
import { onMounted, reactive,ref } from "vue"
import { getAllProductData,getProductDataById,updateInfoData, updateReviewData, updateSignData, updateActiveData } from "@/api/product"
import { postVerifyCode } from "@/api/SMS"
import { useRoute } from "vue-router";
import { StarOutlined } from "@ant-design/icons-vue"
import { getLocationData } from "@/api/geolocation"
let signatureCanvas = ref(null);
let stepThree = ref(null);
const route = new useRoute()
let data = reactive({
  putLoading: false,
  productId: "", // 产品ID
  productData: {}, // 产品数据
  step1Data: {
    location: "",
    provider: "",
    installerName: "",
    constructionArea: ""
  },
  step2Data: {
    customerPhoneNumber: "",
    code: "",
    customerScore: 0
  },
  phoneBtnDisableTime: 0, // 发送验证码等待时间
  disableInterval: null, 
  currentStep: 1,
  signature: null,
  signaturePng: "",
  signaturePngUrl: "",
  activationTimestamp: "" // 激活的时间戳
});


// 获取当前的地理位置
function getLocation(){
    if (navigator.geolocation){
      navigator.geolocation.getCurrentPosition(showPosition, errorPosition);
       
    }
    else
    {
        console.log("该浏览器不支持获取地理位置。")
    }
}

const showPosition = async (position) => {
  var lat = position.coords.latitude;
  var lng = position.coords.longitude;
  let res = await getLocationData(lat, lng);
  console.log(res);
  data.step1Data.location = res.data.address || "";
}

const errorPosition = (error) => {
  message.error("定位失败，请手动输入地址");
}

let initSignature = () => {
  let dom = document.getElementById("app")
  let height = dom.clientHeight;
  let width = dom.clientWidth - 44;
  let option = {
    width: width,
    height: height,
  }
  console.log(option);
  const signature = new SmoothSignature(signatureCanvas.value, option);
  data.signature = signature;
}

let backStep = () => {
  data.currentStep -= 1;
}

let nextStep = async () => {
  let updateData
  data.putLoading = true;
  if(data.currentStep == 1){
    updateData = await verifyStep1Data();
    
  } else if(data.currentStep == 2){
    updateData = await verifyStep2Data();
  }
  else if(data.currentStep == 3){
    updateData = await verifyStep3Data();
  }
  else if(data.currentStep == 4){
    updateData = await verifyActiveData();
  }
  data.putLoading = false;
  if(data.currentStep < 5){
    if(updateData.status){
      data.currentStep += 1;
    } else if(!updateData.status){
      message.error(updateData.message);
    } 
  } else if(data.currentStep == 5){
    data.activationTimestamp = new Date().getTime() / 1000;
    data.currentStep += 1;
  }
  
}



// 验证并更新（第一步）
let verifyStep1Data = async () => {
  console.log(data.step1Data);
  if(!data.step1Data.location){
    return {
      status: false,
      message: "地址未填写"
    }
  }
  if(!data.step1Data.provider){
    return {
      status: false,
      message: "服务商未填写"
    }
  }
  if(!data.step1Data.installerName){
    return {
      status: false,
      message: "安装师傅未填写"
    }
  }
  if(!data.step1Data.constructionArea){
    return {
      status: false,
      message: "施工面积未填写"
    }
  } 
  await updateInfoData(data.productId, data.step1Data.location, data.step1Data.provider, data.step1Data.installerName, data.step1Data.constructionArea)

  return {
    status: true,
    message: "done"
  }
  
}

// 验证并更新 （第二步）
let verifyStep2Data = async () => {
  console.log(data.step2Data);
  var myreg=/^[1][3,4,5,7,8][0-9]{9}$/; //设置手机号正则表达式
  let phoneNumber = data.step2Data.customerPhoneNumber;
  if(phoneNumber == ""){
    return {
      status: false,
      message: "手机号未填写"
    }
  }
  if(!myreg.test(phoneNumber)){ //验证手机号格式是否正确
    return {
      status: false,
      message: "手机号格式不正确"
    }
  }
  if(!data.step2Data.code){
    return {
      status: false,
      message: "未填写验证码"
    }
  }
  if(!data.step2Data.customerScore){
    return {
      status: false,
      message: "未选择评分"
    }
  }
  return new Promise((resolve, reject) => {
    updateReviewData(data.productId, data.step2Data.code, phoneNumber, data.step2Data.customerScore).then((res) => {
      console.log(res);
      if(res.is_success){
        resolve({
          status: true,
          message: "done"
        })
      } else {
        resolve({
          status: false,
          message: res.message
        });
      }
    }).catch((e) => {
        resolve({
          status: false,
          message: "error"
        });
    });
  })
  
}

// 验证并更新 （第三步 签名）
let verifyStep3Data = async () => {
  console.log(data.signature.isEmpty());
  if(data.signature.isEmpty()){
    return {
      status: false,
      message: "请进行签名"
    }
  }
  getsignaturePng()
  let file = await getBase64ToPng(data.productId, data.signaturePng);
  await updateSignData(data.productId, file);
  data.signaturePngUrl = "";
  return {
      status: true,
      message: "done"
  }
}

// 激活 （第四步）
let verifyActiveData = async () => {
  await updateActiveData(data.productId);
  return {
    status: true,
    message: "done"
  }
}

// 发送短信验证码
let postSmsData = () => {
  if(data.phoneBtnDisableTime  > 0){
    return;
  }
  var myreg=/^[1][3,4,5,7,8][0-9]{9}$/; //设置手机号正则表达式
  let phoneNumber = data.step2Data.customerPhoneNumber;
  if(phoneNumber == ""){
    message.error("未填写手机号");
    return;
  }
  if(!myreg.test(phoneNumber)){ //验证手机号格式是否正确
    message.error("手机号格式不正确");
  }
  postVerifyCode(phoneNumber);
  addDisabledInterval();
}

// 定时任务添加发送验证码等待时间
let addDisabledInterval = () => {
  data.phoneBtnDisableTime = 60;
  data.disableInterval = setInterval(() => {
    data.phoneBtnDisableTime -= 1;
    if(data.phoneBtnDisableTime == 0){
      if(data.disableInterval){
        clearInterval(data.disableInterval);
      }
    }
  } , 1000);
}

// 获取签名图片
let getsignaturePng = () => {
  let png = data.signature.getPNG();
  data.signaturePng = png;
}
let clearSignature = () => {
  data.signature.clear();
}


let init = async () => {
  getLocation();
getAllProductData();
  // 获取当前id并存储
  let productId = route.query.id;
  data.productId = productId;
  let productDataRes = await getProductDataById(data.productId);

  data.productData = productDataRes.data;
  
  data.currentStep = data.productData.step + 1;
  if( data.currentStep >= 2){
    data.step1Data.location = data.productData.location;
    data.step1Data.installerName = data.productData.installer_name;
    data.step1Data.provider = data.productData.provider;
    data.step1Data.constructionArea = data.productData.construction_area;
  }
  if( data.currentStep >= 3){
    data.step2Data.customerPhoneNumber = data.productData.customer_phone_number;
    data.step2Data.customerScore = data.productData.customer_score;
  }
  if(data.currentStep >= 4){
    data.signaturePngUrl = data.productData.customer_signature;
  }
  if(data.currentStep == 5 && data.productData.activated){
    data.currentStep = 6;
    data.activationTimestamp = data.productData.activation_timestamp;
  }
}


let getBase64ToPng = (id, base64Str) => {
    var arr = base64Str.split(','),
    mime = arr[0].match(/:(.*?);/)[1], //base64解析出来的图片类型
    bstr = atob(arr[1]), //对base64串进行操作，去掉url头，并转换为byte   atob为window内置方法
    len = bstr.length,
    ab = new ArrayBuffer(len), //将ASCII码小于0的转换为大于0
    u8arr = new Uint8Array(ab); //
    while (len--) {
        u8arr[len] = bstr.charCodeAt(len)
    };
    // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
    return new File([u8arr], "sign" + id, {
        type: mime
     })
}

let getNowFormatDate = (dateTime) => {
  let date = new Date(dateTime);
  let year = date.getFullYear(), //获取完整的年份(4位)
  month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
  strDate = date.getDate(); // 获取当前日(1-31)
  if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
  if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
  return `${year} 年 ${month} 月 ${strDate} 日`;
}

onMounted(async () => {
  init();
  initSignature();
})
</script>
<style lang="less" scoped>
.step-3{
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.sign-img{
    transform: rotate(270deg);
    height: 200px;
    position: absolute;
    bottom: 60px;
    left: 100px;
  }
  .rate{
    display: flex;
    margin-top: 16px;
  }
.step-page{
  padding: 32px;
  height: calc(100% - 80px);
  overflow: auto;
    .info{
      margin-top: 4px;
      font-size: 14px;
    }
    .title{
      margin-top: 4px;
      font-size: 32px;
      font-weight: bold;
    }
    .verify-success{
      color: #3CB078;
      font-size: 14px;
      margin-top: 4px;
      img{
        width: 16px;
        vertical-align: sub;
      }
    }
    .step-2{
      .phone-input{
        position: relative;
        .btn{
          position: absolute;
          right: 4px;
          top: 4px;
          /* border: 1px solid; */
          height: 40px;
          line-height: 40px;
          border-radius: 9px;
          padding: 0 8px;
          background: #B91C25;
          color: #fff;
          &:active{
            background: rgba(185, 28, 37, 0.6);
          }
          &.disabled{
            background-color: rgba(243, 243, 243, 1);
            color: #999;
          }
        }
      }
    }
    .step-4,.step-6{
      display: flex;
      flex-direction: column;
      position: relative;
      .success-title{
        font-size: 32px;
        font-weight: bold;
        img{
          width: 27px;
        }
      }
      .action-item{
        margin-top: 16px;
        .title{
          font-size: 16px;
          color: rgba(56, 56, 56, 0.55);
          line-height: 24px;
        }
        .value{
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }
      .action-row{
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .row-item{
          width: calc(49% - 4px);
        }
      }
    }
    .step-5{
     
      .flex-content{
         display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width: 168px;
        margin-top: 130px;
      }
      .title{
        font-size: 32px;
        margin-top: 48px;
      }
      .info{
        font-size: 16px;
        color: rgba(0, 0, 0, 0.55);
      }
    }}

  }
  .verify-info{
    font-size: 14px;
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.55);
  }

.form-title{
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 24px;
}

.form-input{
  border: 1px solid #DFDFDF;
  width: 100%;
  height: 48px;
  border-radius: 9px;
}

.next-btn{
  width: 100%;
  margin-top: 80px;

}
.back-btn{
  width: 100%;
  margin-top: 20px;
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
}

.footer{
  // position: fixed;
  bottom: 0;
  left: 0;
}

:deep(.ant-rate-star .ant-rate-star-zero .anticon-star){
  color: #000;
}
/deep/ .css-dev-only-do-not-override-19iuou .ant-spin-dot-spin .ant-spin-dot-item{
  background-color: #B91C25;
}
/deep/ .ant-rate .ant-rate-star-full{
  color: #B91C25;
}
</style>
