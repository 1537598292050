<template>
    <div class="header-content">
        <div class="logo-content">
            <img src="@/assets/images/logo.png" class="logo"/>
            <div class="rotate-90">无</div>
            <div class="rotate-90">边</div>
            <div class="rotate-90">界</div>
            <div class="rotate-90">T</div>
            <div class="rotate-90">E</div>
            <div class="rotate-90">C</div>
            <div class="rotate-90">H</div>
            <div class="rotate-90">X</div>
        </div>
        <div>
            <div class="scan-btn rotate-btn" style="margin-bottom: 80px;" @click="clear()">清除</div>
            <div class="scan-btn rotate-btn" @click="nextStep()">下一步</div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from "vue";
let props = defineProps({
    step: {
        type: Number,
        default: 1
    }
});

let emits = defineEmits(["nextStep", "clear"])
let nextStep = () => {
    emits("nextStep");
}
let clear = () => {
    emits("clear");
}
</script>
<style lang="less" scoped>
.rotate-90{
    transform: rotate(90deg);
    line-height: 18px;
}
.rotate-btn{
    transform: rotate(90deg);
    width: 90px;
    height: 32px;
    line-height: 32px;
}
.header-content{
    height: calc(100% - 64px);
    overflow: hidden;
    width: 40px;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .logo-content{
        font-size: 16px;
        font-weight: bold;
        .logo{
            width: 32px;
            vertical-align: middle;
            transform: rotate(90deg);
            margin-bottom: 23px;
        }
    }
    .step-content{
        width: 80px;
        height: 8px;
        display: flex;
        justify-content: space-between;
        .setp-item{
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: #D8D8D8;
            &.finished{
                background-color: #B91C25;
            }
            &.current{
                width: 32px;
                background-color: #B91C25;
            }
        }
    }
}
</style>