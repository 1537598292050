import { request } from './api.js'

const getAllProductData = async () => {
    let url = "/api/product";
    let query = {
        offset: 1,
        limit: 100
    }
    return request(url, query, "get");
}

// 根据id查询单个产品的信息数据
const getProductDataById = (id) => {
    let url = `/api/product/${id}`;
    return request(url, {}, "get");
}

// 更新第一步服务商数据
const updateInfoData = (id, location, provider, installerName, constructionArea) => {
 let url = `/api/product/info/${id}`;
 let param = {
    "location": location,
    "provider": provider,
    "installer_name": installerName,
    "construction_area": constructionArea
 }
 return request(url,param,'put');
}

// 更新第二步用户评价数据
const updateReviewData = (id, code, phoneNumber, score) => {
    let url = `/api/product/review/${id}`;
    let param = {
       "code": code,
       "customer_phone_number": phoneNumber,
       "customer_score": score,
    }
    return request(url,param,'put');
}

// 更新签名
const updateSignData = (id, file) => {
    let url = `/api/product/signature/${id}`;
    let formdata = new FormData();
    formdata.append("file", file);
    return request(url, formdata, 'put')
}

// 激活
const updateActiveData = (id) => {
    let url = `/api/product/active/${id}`;
    return request(url, {}, 'put')
}

export { getAllProductData,getProductDataById, updateInfoData, updateReviewData, updateSignData, updateActiveData}