<template>
  <div class="page">
    <router-view />
  </div>
</template>

<style>
.page{
  height: 100vh;
  width: 100vw;
}
</style>
