import { request } from './api.js'



const getLocationData = async (_x, _y) => {
    let url = "/api/public/loc";
    let query = {
        x: _x,
        y: _y
    }
    return request(url, query, "get");
}
export { getLocationData }