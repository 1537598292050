<template>
    <div class="header-content">
        <div class="logo-content">
            <img src="@/assets/images/logo.png" class="logo"/>
            无边界 TECH X
        </div>
        <div class="step-content" v-if="props.step <= 4">
            <div class="setp-item" :class="getCurrentStautsClass(1)"></div>
            <div class="setp-item" :class="getCurrentStautsClass(2)"></div>
            <div class="setp-item" :class="getCurrentStautsClass(3)" ></div>
            <div class="setp-item" :class="getCurrentStautsClass(4)"></div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from "vue";
let props = defineProps({
    step: {
        type: Number,
        default: 1
    }
})


// 获取当前状态Class
let getCurrentStautsClass = (currentStep) => {
    if(currentStep === props.step){
        return "current";
    } else if(currentStep < props.step){
        return "finished";
    } else {
        return "";
    }
}
</script>
<style lang="less" scoped>
.header-content{
    height: 40px;
    width: 100%;
    display: flex;
    padding: 24px 32px 0;
    justify-content: space-between;
    align-items: center;
    .logo-content{
        font-size: 16px;
        font-weight: bold;
        .logo{
            width: 32px;
            vertical-align: middle;
        }
    }
    .step-content{
        width: 80px;
        height: 8px;
        display: flex;
        justify-content: space-between;
        .setp-item{
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: #D8D8D8;
            &.finished{
                background-color: #B91C25;
            }
            &.current{
                width: 32px;
                background-color: #B91C25;
            }
        }
    }
}
</style>