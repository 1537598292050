import axios from "axios"


axios.defaults.timeout = 60000;

// 请求地址，这里是动态赋值的的环境变量，下一篇会细讲，这里跳过
// @ts-ignore
axios.defaults.baseURL = "https://api.tmgxbxwl.cn/";   


//http response 拦截器
axios.interceptors.response.use(
    response => {
      return response.data;
    },
    error => {
      console.log(error)
    }
  );

// 封装 GET POST 请求并导出
export function request(url='',params={},type='POST'){
    //设置 url params type 的默认值
    return new Promise((resolve,reject)=>{
    let promise
    if( type.toUpperCase()==='GET' ){
        promise = axios({
        url,
        params
        })
    }else if( type.toUpperCase()=== 'POST' ){
        promise = axios({
        method:'POST',
        url,
        data:params
        })
    } else if( type.toUpperCase() === "PUT"){
        promise = axios({
            method:'PUT',
            url,
            data:params
        })
    }
    //处理返回
    promise.then(res=>{
        resolve(res)
    }).catch(err=>{
        reject(err)
    })
    })
}